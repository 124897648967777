<template>
  <section>
    <b-card>
      <div class="d-flex justify-content-between mb-2 align-items-center">
        <div class="h3">
          <i class="feather icon-save text-primary mr-1" />Progetti Salvati
        </div>
        <div>
          <b-button class="btn btn-primary" @click="$router.push('/new_project_type')" variant="primary">+ Nuovo Progetto
          </b-button>
        </div>
      </div>

      <hr />

      <div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Cerca</label>
              <b-form-input v-model="searchTerm" type="text" class="d-inline-block" />
            </div>
          </b-form-group>
        </div>

        <vue-good-table ref="projectTable" :columns="columns" :rows="tableItems" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}">
          <template slot="table-row" slot-scope="props">
            <!-- Column: ID -->
            <div v-if="props.column.field === 'projectId'" class="text-nowrap">
              <span class="text-nowrap">{{ props.row.projectId }}</span>
            </div>

            <div v-if="props.column.field === 'project_status'" class="text-nowrap">
              <span v-if="props.row.project_status == 'draft'">In Bozza</span>
              <span v-else>Dati Confermati</span>
            </div>

            <!-- Column: Action -->
            <div class="d-flex flex-row" v-else-if="props.column.field === 'action'">
              <b-dropdown id="project-actions" text="Azioni" :boundary="$refs.projectTable.$el">
                <b-dropdown-item-button :disabled="props.row.project_status == 'ready'"
                  :variant="props.row.project_status == 'ready' ? '' : 'primary'"
                  class="text-body align-middle cursor-pointer w-100"
                  @click="props.row.project_type == 1 
                    ? $router.push('/edit_project/' + props.row.id_project)
                    : $router.push('/edit_project_maintenance/' + props.row.id_project)">Modifica</b-dropdown-item-button>

                <b-dropdown-item-button v-b-modal.modal-2 variant="primary" class="w-100" v-if="props.row.project_type == 1"
                  @click="currentProjectId = props.row.id_project; currentProjectReady = props.row.project_status == 'ready'; getProjectSummary(props.row.id_project)">
                  Riepilogo Dati
                </b-dropdown-item-button>

                <form v-if="props.row.project_status == 'ready' && props.row.project_type == 1" ref="pdf" :action="BACKEND_BASE_URL + '/module/pdf.php?id=' + props.row.id_project" target="_blank" method="post" class="">
                  <input type="hidden" :value="user_token" />
                  <b-dropdown-item-button class="w-100" variant="primary" :type="'submit'" v-on:click.prevent="openPdf($event)">Apri PDF</b-dropdown-item-button>
                </form>
                <b-dropdown-item-button v-else-if="props.row.project_type == 1" v-b-modal.modal-1 class="w-100"> Apri PDF </b-dropdown-item-button>

                <form v-if="props.row.project_status == 'ready' && props.row.project_type == 2" ref="pdf" :action="BACKEND_BASE_URL + '/module_mantainer/pdf.php?id=' + props.row.id_project" target="_blank" method="post" class="">
                  <input type="hidden" :value="user_token" />
                  <b-dropdown-item-button class="w-100" variant="primary" :type="'submit'" v-on:click.prevent="openPdf($event)">Apri PDF</b-dropdown-item-button>
                </form>

                <b-dropdown-item-button v-on:click.prevent="confirmMaintenanceData(props.row.id_project)" variant="primary" :type="button" v-if="props.row.project_status == 'draft' && props.row.project_type == 2">Conferma dati</b-dropdown-item-button>

                

                <b-dropdown-item-button v-if="props.row.project_type == 1" @click="openAttachments('attachment-links', props.row.id_project)" variant="primary" class="w-100">Allegati
                </b-dropdown-item-button>
                <b-dropdown-item-button v-else-if="props.row.project_type == 2" @click="openMaintenanceAttachments('attachment-links', props.row.id_project)" variant="primary" class="w-100">Allegati
                </b-dropdown-item-button>

                <b-dropdown-item-button v-if="props.row.project_type == 1" variant="danger" class="" @click="deleteProject($event, props.formattedRow)">
                  Elimina</b-dropdown-item-button>
                <b-dropdown-item-button v-if="props.row.project_type == 2" variant="danger" class="" @click="deleteMaintenanceProject($event, props.formattedRow)">
                  Elimina</b-dropdown-item-button>
              </b-dropdown>

            </div>

            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>

    <!-- Modals -->

    <b-modal id="modal-1" title="Attenzione" size="lg" hide-footer>
      <b-card-text>
        Devi confermare i dati prima di poter generare un PDF per il progetto.
      </b-card-text>
    </b-modal>




    <b-modal id="modal-2" @ok="confirmData" @hidden="currentProjectId = null; currentProjectReady = false"
      :hide-footer="currentProjectReady == true" scrollable title="Riepilogo Dati" size="xl" ok-title="Conferma dati"
      cancel-title="Chiudi">
      <div v-if="summary_loading" class="d-flex justify-content-center align-items-center my-2">
        <b-spinner v-if="summary_loading" variant="primary" />
        <span class="ml-2 text-primary">Recupero informazioni...</span>
      </div>
      <b-card-text v-else v-html="summary"> </b-card-text>
    </b-modal>

    <b-modal id="attachment-links" title="Allegati relazione tecnica" size="lg" ok-only ok-title="OK">
      <ul v-if="links && links.length">
        <li v-for="link in links"><a target="_blank" v-bind:href="link.url.startsWith('storage') ? `${BACKEND_BASE_URL}/${link.url}`: `${STORAGE_URL}/${link.url}`">{{ link.title }}</a></li>
      </ul>
      <h2 v-else>Nessun allegato presente</h2>
    </b-modal>
  </section>
</template>

<script>
import {
  BAvatar,
  BButton,
  BBadge,
  BCard,
  BCardText,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItemButton,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import { Requests } from "@/api/requests.js";
import { BACKEND_BASE_URL } from "@/config";
import { STORAGE_URL } from "@/config";

export default {
  components: {
    BAvatar,
    BButton,
    BBadge,
    BCard,
    BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItemButton,
    BSpinner,
    VueGoodTable,
  },

  data() {
    return {
      user: null,
      links: [],
      BACKEND_BASE_URL: BACKEND_BASE_URL,
      STORAGE_URL: STORAGE_URL,
      summary: null,
      pageLength: 7,
      dir: false,
      currentProjectId: null,
      currentProjectReady: false,
      columns: [
        {
          label: "ID",
          field: "id_project",
          type: 'number'
        },
        {
          label: "Status",
          field: "project_status",
        },
        {
          label: "Progetto",
          field: "project_name",
        },
        {
          label: "Cliente",
          field: "project_customer_companyname",
        },
        {
          label: "Data creazione",
          field: "creation_date",
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: "Azioni",
          field: "action",
        },
      ],
      tableItems: [],
      searchTerm: "",
      user_token: "",

      //Helpers

      summary_loading: false,
    };
  },

  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.user_token = localStorage.getItem("access_token");
    this.getAllProjects();
  },

  updated() {

    for (const button of [...this.$el.querySelectorAll('button.dropdown-item')]) {
      button.setAttribute('type', 'submit');
    }
  },

  methods: {
    openPdf(event) {
      event.preventDefault();
      debugger;
      if (this.user.plan_type == 'trial') {
        this.$swal("Attenzione", "È necessario un account a consumo o un abbonamento mensile/annuale per stampare il PDF", "error");
      } else {
        event.target.parentElement.parentElement.submit();
      }
    },
    async openAttachments(modalId, projectId) {
      await this.getProjectAttachments(projectId);
      this.$bvModal.show(modalId);
    },
    async openMaintenanceAttachments(modalId, projectId) {
      await this.getProjectMaintenanceAttachments(projectId);
      this.$bvModal.show(modalId);
    },

    async deleteProject(event, project) {
      const result = await this.$swal.fire({
        title: 'Attenzione',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        text: `Eliminare il progetto ${project.project_name}?`
      });
      if (result.isConfirmed) {
        let data = new FormData();

        data.append('id_project', project.id_project);
        try {
          await Requests.deleteProject(data);
          await this.$swal.fire('OK', 'Progetto eliminato', 'success');
          this.getAllProjects();
        } catch (e) {
          this.$swal.fire('Attenzione', 'Si è verificato un errore', 'error');
        }
      }
    },

    async deleteMaintenanceProject(event, project) {
      const result = await this.$swal.fire({
        title: 'Attenzione',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        text: `Eliminare il progetto ${project.project_name}?`
      });
      if (result.isConfirmed) {
        let data = new FormData();

        data.append('id_project', project.id_project);
        try {
          await Requests.deleteMaintenanceProject(data);
          await this.$swal.fire('OK', 'Progetto eliminato', 'success');
          this.getAllProjects();
        } catch (e) {
          debugger;
          this.$swal.fire('Attenzione', 'Si è verificato un errore: ' + e, 'error');
        }
      }
    },

    async getProjectAttachments(project_id) {
      const fd = new FormData();
      fd.append('project_id', project_id);
      const data = await Requests.getProjectAttachments(fd);
      this.links = data;
    },

    async getProjectMaintenanceAttachments(project_id) {
      const fd = new FormData();
      fd.append('project_id', project_id);
      const data = await Requests.getProjectMaintenanceAttachments(fd);
      this.links = data;
    },

    async confirmData(event) {
      event.preventDefault();
      const res = await this.$swal({
        title: 'Sei sicuro?',
        text: 'Vuoi confermare i dati? In seguito non potrai più modificare i dati',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      })

      if (res.isConfirmed) {
        try {
          const response = await Requests.confirmData(this.currentProjectId);
          if (!response[0].confirm_project.success) {
            this.$swal('Attenzione', 'Si è verificato un errore', 'error');
            return;
          }
          await this.$swal('OK', 'I dati sono stati confermati', 'success');
          this.$router.go();
        } catch {
          await this.$swal('Attenzione', 'Si è verificato un errore', 'error');
        }
      }

    },

    async confirmMaintenanceData(id_project) {
      const res = await this.$swal({
        title: 'Sei sicuro?',
        text: 'Vuoi confermare i dati? In seguito non potrai più modificare i dati',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      })

      if (res.isConfirmed) {
        try {
          const response = await Requests.confirmMaintenanceData(id_project);
          if (!response[0].confirm_maintenance_project.success) {
            this.$swal('Attenzione', 'Si è verificato un errore', 'error');
            return;
          }
          await this.$swal('OK', 'I dati sono stati confermati', 'success');
          this.$router.go();
        } catch {
          await this.$swal('Attenzione', 'Si è verificato un errore', 'error');
        }
      }

    },
    async getAllProjects() {
      try {
        const response = await Requests.getAllProjects();
        this.tableItems = response.data;
      } catch (err) {
        console.log(err);
      }
    },

    async getProjectSummary(id) {
      this.summary_loading = true;

      let request_data = new FormData();

      request_data.append("id", id);

      try {
        const response = await Requests.getProjectSummary(request_data);
        this.summary = response;
        this.$bvModal.show("modal-2");
      } catch (err) {
        console.log(err);
      }

      this.summary_loading = false;
    },
  },
};
</script>

<style>
.icon-button {
  background: transparent;
  border: none;
}

.vgt-responsive {
  overflow: visible;
}
</style>
